import {
  changeObjectRole,
  compareObjectRolesAndNames,
  enumToCode,
  getFieldArgumentsObjectTypeNameParts,
  objectToCode,
  queryRootArgsObjectTypeName,
  removeEnum,
  removeObject,
  removeObjectFieldsFromExpression,
  rootObjectTypeNameFromSchema,
  setEnumDescription,
  setObjectDescription,
  toStartCase,
} from "@hypertune/shared-internal";
import { ArrowSquareRight, Copy, NotePencil } from "@phosphor-icons/react";
import { asError, Schema, Expression } from "@hypertune/sdk/src/shared";
import { ArrowRight } from "@phosphor-icons/react/dist/ssr";
import {
  Code,
  Description,
  DetailContainer,
} from "../../../../components/Details";
import {
  setDraftCommitSchema,
  setDraftCommitSchemaAndExpression,
  setNewTypeModalState,
} from "../../projectSlice";
import DeleteWithConfirmationButton from "./DeleteWithConfirmationButton";
import { SelectedType, TypeReferencesMap } from "../schemaHooks";
import { useAppDispatch } from "../../../../app/hooks";
import Button from "../../../../components/buttons/Button";
import TypeIcon from "../../../../components/icons/TypeIcon";
import Label from "../../../../components/Label";
import { lighterGreyHex } from "../../../../lib/constants";
import twMerge from "../../../../lib/twMerge";
import canCloneObjectType from "../../../../lib/canCloneObjectType";

export default function TypeDetails({
  readOnly,
  schema,
  expression,
  selectedType,
  setSelectedType,
  setErrorMessage,
  disableActions,
  typeReferences,
  isSelectedTypeRoot,
  isSelectedTypeContext,
}: {
  readOnly: boolean;
  schema: Schema;
  expression: Expression;
  selectedType: SelectedType;
  setSelectedType: (newSelectedType: SelectedType | null) => void;
  setErrorMessage: (newErrorMessage: string | null) => void;
  disableActions: boolean;
  typeReferences: TypeReferencesMap;
  isSelectedTypeRoot: boolean;
  isSelectedTypeContext: boolean;
}): React.ReactElement | null {
  const dispatch = useAppDispatch();
  const rootObjectTypeName = rootObjectTypeNameFromSchema(schema);
  const cloneEnabled = canCloneObjectType(schema, selectedType.name);
  const showConvertAction =
    cloneEnabled &&
    (!typeReferences[selectedType.name] ||
      typeReferences[selectedType.name].size === 0);

  return (
    <>
      <Description
        readOnly={readOnly}
        text={
          (selectedType.type === "enum"
            ? schema.enums[selectedType.name].description
            : schema.objects[selectedType.name].description) || ""
        }
        setText={(newDescription) =>
          dispatch(
            setDraftCommitSchema(
              selectedType.type === "enum"
                ? setEnumDescription(
                    schema,
                    selectedType.name,
                    newDescription || null
                  )
                : setObjectDescription(
                    schema,
                    selectedType.name,
                    newDescription || null
                  )
            )
          )
        }
      />
      <Code
        code={
          (selectedType.type === "enum"
            ? enumToCode(schema, selectedType.name, true)
            : objectToCode(schema, selectedType.name, true)) || ""
        }
      />
      {!isSelectedTypeRoot &&
        typeReferences[selectedType.name] &&
        (!isSelectedTypeContext ||
          typeReferences[selectedType.name].size > 1) && (
          <DetailContainer
            icon={<ArrowSquareRight weight="regular" />}
            title="References"
          >
            <div className="-ml-2 flex flex-col items-stretch">
              {[...typeReferences[selectedType.name].values()]
                .filter(({ name }) => name !== queryRootArgsObjectTypeName)
                .sort((a, b) => {
                  const aRole = a.type === "object" ? "output" : a.type;
                  const bRole = b.type === "object" ? "output" : b.type;
                  if (aRole === "enum" || bRole === "enum") {
                    throw new Error("unexpected enum reference");
                  }
                  if (aRole === "union" || bRole === "union") {
                    if (aRole !== "union") {
                      return 1;
                    }
                    if (bRole !== "union") {
                      return -1;
                    }
                    return a.name.localeCompare(b.name);
                  }

                  return compareObjectRolesAndNames(
                    rootObjectTypeName,
                    aRole,
                    a.name,
                    bRole,
                    b.name
                  );
                })
                .map((ref) => {
                  const args = getFieldArgumentsObjectTypeNameParts(ref.name);
                  return (
                    <button
                      type="button"
                      className="group flex flex-row items-center gap-2 rounded-lg p-2 hover:bg-bg-pressed"
                      onClick={() =>
                        setSelectedType({
                          type: ref.type,
                          name: args?.parentObjectTypeName ?? ref.name,
                          selectedChildName: args?.fieldName ?? null,
                        })
                      }
                    >
                      <TypeIcon
                        type={args ? "object" : ref.type}
                        size="small"
                      />
                      <Label
                        type="title4"
                        className={twMerge(
                          "min-w-0 overflow-hidden text-ellipsis whitespace-nowrap leading-4",
                          ref.type === "event"
                            ? "text-type-icon-green"
                            : ref.type === "enum"
                              ? "text-type-icon-yellow"
                              : "text-type-icon-blue"
                        )}
                      >
                        {args
                          ? `${toStartCase(args.parentObjectTypeName)} / ${toStartCase(args.fieldName)}`
                          : toStartCase(ref.name)}
                      </Label>
                      <ArrowRight
                        weight="regular"
                        size={16}
                        color={lighterGreyHex}
                        className="ml-auto opacity-0 group-hover:opacity-100"
                      />
                    </button>
                  );
                })}
            </div>
          </DetailContainer>
        )}
      {!readOnly && !disableActions && (
        <DetailContainer icon={<NotePencil />} title="Actions">
          <div className="flex flex-row gap-2">
            <DeleteWithConfirmationButton
              entityName="type"
              onClick={() => {
                try {
                  const { schema: newSchema, removedFields } =
                    selectedType.type === "enum"
                      ? removeEnum(schema, selectedType.name)
                      : removeObject(schema, selectedType.name);

                  dispatch(
                    setDraftCommitSchemaAndExpression({
                      schema: newSchema,
                      expression: removeObjectFieldsFromExpression(
                        schema,
                        expression,
                        removedFields
                      ),
                    })
                  );
                  setSelectedType(null);
                } catch (error) {
                  setErrorMessage(asError(error).message);
                }
              }}
            />
            {cloneEnabled && (
              <Button
                icon={
                  !showConvertAction ? <Copy weight="regular" /> : undefined
                }
                weight="elevated"
                text={
                  showConvertAction
                    ? `Convert to ${selectedType.type === "input" ? "object" : "input"}`
                    : "Clone"
                }
                onClick={() => {
                  dispatch(
                    showConvertAction
                      ? setDraftCommitSchema(
                          changeObjectRole(
                            schema,
                            selectedType.name,
                            selectedType.type === "input" ? "output" : "input"
                          )
                        )
                      : setNewTypeModalState({
                          sourceObjectTypeName: selectedType.name,
                        })
                  );
                  if (showConvertAction) {
                    setSelectedType({
                      ...selectedType,
                      type: selectedType.type === "input" ? "object" : "input",
                    });
                  }
                }}
              />
            )}
          </div>
        </DetailContainer>
      )}
    </>
  );
}
