import {
  Calculator,
  ChartBar,
  Eye,
  LetterCircleP,
  NumberCircleOne,
  NumberCircleTwo,
  Pencil,
} from "@phosphor-icons/react";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUpdateAnalyticsViewMutation } from "../../../generated/graphql";
import BigIcon from "../../../components/BigIcon";
import RenameTopBar from "../../../components/RenameTopBar";
import { whiteHex } from "../../../lib/constants";
import TimeRangePicker from "../../../components/TimeRangePicker";
import { TimeRange } from "../../../lib/types";
import SaveButton from "./SaveButton";
import DeleteButton from "./DeleteButton";
import TopBarDropdown from "../../../components/TopBarDropdown";
import ResetButton from "./ResetButton";
import { refetchAnalyticsViewsQueries } from "../../../lib/query/refetchQueries";
import ErrorMessage from "../../../components/ErrorMessage";
import ModeSwitcher from "../../../components/ModeSwitcher";
import { useHypertune } from "../../../generated/hypertune.react";
import ModalWithContent from "../../../components/ModalWithContent";
import { HypothesisVariant, hypothesisVariants } from "./analyticsSlice";
import { TestStatisticsDropdownOptions } from "../../../generated/hypertune";

export default function AnalyticsTopBar({
  canEdit,
  selectedHasError,
  viewId,
  viewName,
  timeRange,
  selectedMetric,
  setSelectedTimeRange,
  setSelectedMetric,
  focusModeEnabled,
  setFocusModeEnabled,
  metricVariants,
  selectedHypothesis,
  setSelectedHypothesis,
}: {
  canEdit: boolean;
  selectedHasError: boolean;
  viewId: string;
  viewName: string;
  timeRange: TimeRange;
  selectedMetric: keyof TestStatisticsDropdownOptions;
  setSelectedTimeRange: (newTimeRange: TimeRange) => void;
  setSelectedMetric: React.Dispatch<
    React.SetStateAction<keyof TestStatisticsDropdownOptions>
  >;
  focusModeEnabled: boolean;
  setFocusModeEnabled: (enabled: boolean) => void;
  metricVariants: TestStatisticsDropdownOptions;
  selectedHypothesis: HypothesisVariant;
  setSelectedHypothesis: (newHypothesis: HypothesisVariant) => void;
}): React.ReactElement {
  const hypertune = useHypertune();
  const navigate = useNavigate();

  const [showAnalyticsModal, setShowAanalyticsModal] = useState<boolean>(false);
  const [updateView, { loading }] = useUpdateAnalyticsViewMutation({
    refetchQueries: refetchAnalyticsViewsQueries,
    awaitRefetchQueries: true,
  });

  const plans = hypertune.content().plans();

  const rename = useCallback(
    async (newName: string) => {
      const trimmedName = newName.trim();
      if (loading || !trimmedName) {
        return;
      }
      try {
        await updateView({
          variables: {
            input: {
              id: viewId,
              name: trimmedName,
            },
          },
        });
      } catch (error) {
        console.error("[updateAnalyticsView] error:", error);
      }
    },
    [updateView, loading, viewId]
  );

  const metricVariantOptions: {
    value: keyof TestStatisticsDropdownOptions;
    label: string;
    icon: JSX.Element;
    showIconWhenSelected: boolean;
  }[] = Object.entries(metricVariants)
    .filter(([variant, _]) => variant !== "__typename")
    .map(([variant, label]) => {
      const key = variant as keyof TestStatisticsDropdownOptions;
      return {
        value: key,
        label,
        icon: key === "bayesian" ? <LetterCircleP /> : <Calculator />,
        showIconWhenSelected: true,
      };
    });

  const hypothesisVariantsOptions = hypothesisVariants.map((variant) => ({
    value: variant,
    label: variant,
    icon: variant === "One-sided" ? <NumberCircleOne /> : <NumberCircleTwo />,
    showIconWhenSelected: true,
  }));

  return (
    <>
      <RenameTopBar
        icon={
          <BigIcon
            size={28}
            icon={<ChartBar color={whiteHex} weight="fill" />}
            className="bg-type-icon-blue"
          />
        }
        label={viewName}
        entityName="funnel"
        disableConfirmDialog
        rename={rename}
        actions={
          canEdit ? (
            <div className="flex flex-row gap-2">
              {hypertune.showAnalyticsFocusMode({ fallback: false }) && (
                <ModeSwitcher
                  modes={[
                    { icon: <Pencil />, value: false },
                    { icon: <Eye />, value: true },
                  ]}
                  selectedMode={focusModeEnabled}
                  setMode={setFocusModeEnabled}
                  className="shadow-button"
                />
              )}
              <DeleteButton />
              <ResetButton />
              <SaveButton disabled={selectedHasError} />
            </div>
          ) : undefined
        }
        errorMessage={
          selectedHasError && <ErrorMessage errorMessage="Funnel has error" />
        }
      >
        <div className="flex flex-row items-center gap-2">
          <TimeRangePicker range={timeRange} setRange={setSelectedTimeRange} />
          {hypertune.analyticsStatsRollout({ fallback: false }) && (
            <>
              <TopBarDropdown
                value={
                  metricVariantOptions.find(
                    (option) => option.value === selectedMetric
                  ) || null
                }
                placeholder="Select metric"
                options={{
                  type: "options",
                  options: metricVariantOptions.filter(
                    (option) => option.value !== "default"
                  ),
                }}
                onChange={(newOption) => {
                  if (
                    newOption?.value &&
                    hypertune
                      .features()
                      .analyticsProbabilityMetricsEnabled({ fallback: false })
                  ) {
                    setSelectedMetric(newOption.value);
                    return;
                  }
                  setShowAanalyticsModal(true);
                }}
                dropdownStyle={{
                  hideSearch: true,
                  caret: "down",
                  buttonClassName:
                    "py-[7px] px-[8px] leading-none font-medium text-tx-default whitespace-nowrap",
                  panelClassName: "-mt-4 data-top:mt-4 data-top:-mb-4",
                  optionClassName: "font-medium",
                }}
              />
              {(selectedMetric === "frequentist90ConfidenceLevel" ||
                selectedMetric === "frequentist95ConfidenceLevel" ||
                selectedMetric === "frequentist99ConfidenceLevel") && (
                <TopBarDropdown
                  value={
                    hypothesisVariantsOptions.find(
                      (variant) => variant.value === selectedHypothesis
                    ) || null
                  }
                  placeholder="One-sided"
                  options={{
                    type: "options",
                    options: hypothesisVariantsOptions,
                  }}
                  onChange={(newOption) => {
                    if (newOption) {
                      setSelectedHypothesis(newOption.value);
                    }
                  }}
                  dropdownStyle={{
                    hideSearch: true,
                    caret: "down",
                    buttonClassName:
                      "py-[7px] px-[8px] leading-none font-medium text-tx-default whitespace-nowrap",
                    panelClassName: "-mt-4 data-top:mt-4 data-top:-mb-4",
                    optionClassName: "font-medium",
                  }}
                />
              )}
            </>
          )}
        </div>
      </RenameTopBar>
      {showAnalyticsModal && (
        <ModalWithContent
          content={plans.createAnalyticsUpgradeModal().get()}
          onSave={() => navigate("/plans")}
          onClose={() => setShowAanalyticsModal(false)}
          saveWeight="filled"
        />
      )}
    </>
  );
}
