import React, { useEffect } from "react";
import { useBeforeUnload, useBlocker, useParams } from "react-router-dom";
import { Schema } from "@hypertune/sdk/src/shared";
import { getEpsilonGreedySplitConfig } from "@hypertune/shared-internal";
import {
  CommitMetadata,
  DiffCommitData,
  EditorState,
  ExpressionEditorState,
} from "../../lib/types";
import CommitInner from "./CommitInner";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  setDraftCommit,
  setDraftCommitExpression,
  setDraftCommitSplits,
  DraftCommit,
  FullDraftCommitDerivedData,
} from "./projectSlice";
import { ProjectBranchQuery, ProjectQuery } from "../../generated/graphql";
import { useStopAnalyticsViewNavigation } from "./analytics/analyticsHooks";
import { ProjectView, skipNavigationBlockerKey } from "./projectHooks";
import getDefaultQueryCode from "../../lib/expression/getDefaultQueryCode";
import { ExpressionNodeMap } from "./expression/toTree";
import { selectedPRIdUrlKey } from "./pull-request/pullRequestHooks";
import { useLogicSetSelectedFieldPath } from "./logicHooks";

const initialExpressionEditorState: ExpressionEditorState = {
  selectedItem: null,
  collapsedExpressionIds: {},
};

export default function Project({
  selectedView,
  meId,
  readOnly,
  canEdit,
  canContribute,
  project,
  branch,
  commitId,
  commit,
  commitDerivedData,
  expressionTree,
  draftHasChanges,
  baseCommit,
  meta,
}: {
  selectedView: ProjectView;
  meId: string;
  readOnly: boolean;
  canEdit: boolean;
  canContribute: boolean;
  project: ProjectQuery["project"];
  branch: ProjectBranchQuery["projectBranch"];
  commitId: string | null;
  commit: DraftCommit;
  commitDerivedData: FullDraftCommitDerivedData;
  expressionTree?: ExpressionNodeMap;
  draftHasChanges: boolean;
  baseCommit: DiffCommitData;
  meta?: CommitMetadata;
}): React.ReactElement | null {
  const dispatch = useAppDispatch();
  const setLogicSelectedFieldPath = useLogicSetSelectedFieldPath();
  const analyticsHasChanges = useAppSelector(
    (state) => state.projectAnalytics.hasChanges
  );
  const {
    schemaCodeError,
    logicError,
    splitsError,
    defaultFieldPath,
    hasChanges,
  } = commitDerivedData;

  const { selectedCommitId } = useParams();

  const stopAnAlyticsNavigation = useStopAnalyticsViewNavigation();

  // Handles navigating away from the page
  useBlocker(({ currentLocation, nextLocation }) => {
    // Check whether it's fine navigating away from analytics view first.
    const currentSearch = new URLSearchParams(currentLocation.search);
    const nextSearch = new URLSearchParams(nextLocation.search);

    if (nextSearch.get(skipNavigationBlockerKey) === "1") {
      // Allow change when explicitly skipping blocker.
      return false;
    }

    const currentPathParts = currentLocation.pathname.split("/").reverse();
    const nextPathParts = nextLocation.pathname.startsWith("/projects/")
      ? nextLocation.pathname.split("/").reverse()
      : [];
    const [currentView, currentCommitId, currentBranch, currentProjectId] =
      currentPathParts;
    const [nextView, nextCommitId, nextBranch, nextProjectId] = nextPathParts;

    if (currentLocation.pathname === nextLocation.pathname) {
      // We are staying in the same project view.
      // Changing search params shouldn't trigger the blocker.
      return false;
    }
    // Check analytics changes.
    if (stopAnAlyticsNavigation()) {
      return true;
    }
    if (
      currentProjectId === nextProjectId &&
      currentBranch === nextBranch &&
      currentCommitId === nextCommitId
    ) {
      // Changing view shouldn't trigger the blocker.
      return false;
    }

    if (
      currentSearch.get(selectedPRIdUrlKey) !==
      nextSearch.get(selectedPRIdUrlKey)
    ) {
      // This handles the case when a contributor creates
      // a PR from draft on default branch.
      return false;
    }

    // Navigating away from project view without changes is allowed.
    if (!nextBranch && !draftHasChanges) {
      return false;
    }

    // Allow changing selected commit.
    if (currentBranch === nextBranch) {
      return false;
    }

    // Allow changing branches when there are no changes.
    if (currentBranch !== nextBranch && !draftHasChanges) {
      // Reset state when changing branches.
      dispatch(setDraftCommit(undefined));
      return false;
    }

    console.log(
      "[useBlocker:Project] prompted user to confirm they want to leave",
      { currentBranch, nextBranch, draftHasChanges }
    );
    // eslint-disable-next-line no-restricted-globals, no-alert
    if (confirm("Leave site? Changes that you made may not be saved.")) {
      if (currentBranch !== nextBranch) {
        // Reset state when changing branches.
        dispatch(setDraftCommit(undefined));
      }
      return false;
    }
    return true;
  });
  // Handles closing the page
  useBeforeUnload(
    React.useCallback(
      (event) => {
        if (analyticsHasChanges || draftHasChanges) {
          console.log(
            "[useBeforeUnload:project] prompted user to confirm they want to leave"
          );
          event.preventDefault();
          // This value is overridden by most modern browsers
          // eslint-disable-next-line no-param-reassign
          event.returnValue =
            "Leave site? Changes that you made may not be saved.";
        }
      },
      [analyticsHasChanges, draftHasChanges]
    )
  );

  const [editorState, setEditorState] = React.useState<EditorState>({
    projectId: project?.id || "",
    debug: {
      expressionEditorState: initialExpressionEditorState,
      queryCode:
        getDefaultQueryCode({
          schema: JSON.parse(branch.activeCommit.schema.json) as Schema,
          includeDeprecated: true,
          includeComments: true,
          includeArguments: false,
          useSharedFragments: false,
        }) || "",
      variablesCode: "{}",
      markQueryFieldArgumentsPartial: true,
      userAgent: navigator.userAgent,
      referer: "",
      hasChanges: false,
    },
    logs: { selectedLogId: null },
  });
  useEffect(() => {
    setEditorState((prevEditorState) => {
      if (
        project?.id === prevEditorState.projectId &&
        prevEditorState.debug.queryCode &&
        prevEditorState.debug.hasChanges
      ) {
        return prevEditorState;
      }
      if (commit.schema && schemaCodeError) {
        // Don't try to regenerate query when there are schema errors.
        return prevEditorState;
      }
      return {
        ...prevEditorState,
        projectId: project?.id || "",
        debug: {
          ...prevEditorState.debug,
          hasChanges: false,
          queryCode:
            getDefaultQueryCode({
              schema: schemaCodeError
                ? (JSON.parse(branch.activeCommit.schema.json) as Schema)
                : commit.schema,
              includeDeprecated: true,
              includeComments: true,
              includeArguments: false,
              useSharedFragments: false,
            }) || "",
        },
      };
    });
  }, [project?.id, branch, commit.schema, schemaCodeError]);

  if (!project || !branch || !expressionTree || !selectedCommitId) {
    return null;
  }

  return (
    <CommitInner
      selectedView={selectedView}
      meId={meId}
      project={project}
      commitId={commitId}
      schema={commit.schema}
      logicError={logicError}
      schemaCodeError={schemaCodeError}
      readOnlySchemaCode={commit.readOnlySchemaCode}
      editableSchemaCode={commit.editableSchemaCode}
      implementationContext={{
        splits: commit.splits,
        eventTypes: commit.eventTypes,
        setSplits: readOnly
          ? noop
          : (newSplits) => {
              dispatch(setDraftCommitSplits(newSplits));
            },
      }}
      expression={commit.expression}
      expressionTree={expressionTree}
      setExpression={
        readOnly
          ? noop
          : (newExpression) => {
              dispatch(setDraftCommitExpression(newExpression));
            }
      }
      commitConfig={{
        splitConfig: Object.fromEntries(
          Object.values(commit.splits).map((split) => [
            split.id,
            getEpsilonGreedySplitConfig(split, {}),
          ])
        ),
      }}
      editorState={editorState}
      setEditorState={setEditorState}
      setLogicSelectedFieldPath={setLogicSelectedFieldPath}
      canEdit={canEdit}
      canContribute={canContribute}
      readOnly={readOnly}
      baseCommit={baseCommit}
      defaultFieldPath={defaultFieldPath}
      hasChanges={hasChanges}
      hasError={!!logicError || !!schemaCodeError || !!splitsError}
      meta={meta}
    />
  );
}

function noop(): void {
  // Dummy noop
}
