import { MouseEventHandler } from "react";
import {
  mapExpressionWithResult,
  ApplicationExpression,
  Expression,
  FunctionExpression,
  VariableExpression,
  uniqueId,
} from "@hypertune/sdk/src/shared";
import getConstraintFromValueType from "@hypertune/shared-internal/src/expression/constraint/getConstraintFromValueType";
import getNewVariables from "@hypertune/shared-internal/src/expression/getNewVariables";
import isValueTypeValid from "@hypertune/shared-internal/src/expression/isValueTypeValid";
import {
  ValueTypeConstraint,
  Variable,
  VariableMap,
} from "@hypertune/shared-internal/src/expression/types";
import getSetVariableNameFunction from "../../../lib/expression/getSetVariableNameFunction";
import {
  ExpressionControlContext,
  IncludeExpressionOptionFunction,
  LiftFunction,
  VariableContext,
} from "../../../lib/types";
import ExpressionControl from "./ExpressionControl";

export default function FunctionExpressionControl({
  context,
  variables,
  setVariableName,
  parentExpression,
  expression,
  setExpression,
  lift,
  forBody,
  variableContext,
  includeExpressionOption,
}: {
  context: ExpressionControlContext;
  variables: VariableMap;
  setVariableName: { [variableId: string]: (newVariableName: string) => void };
  parentExpression: Expression | null;
  expression: FunctionExpression;
  setExpression: (newExpression: Expression | null) => void;
  lift: LiftFunction;
  forBody: {
    shouldStack: boolean;
    collapse?: MouseEventHandler;
  };
  variableContext?: VariableContext;
  includeExpressionOption: IncludeExpressionOptionFunction;
}): React.ReactElement {
  const bodyValueTypeConstraint: ValueTypeConstraint = isValueTypeValid(
    context.commitContext.schema,
    expression.valueType
  )
    ? getConstraintFromValueType(expression.valueType.returnValueType)
    : { type: "ErrorValueTypeConstraint" };

  return (
    <ExpressionControl
      context={context}
      variables={{
        ...variables,
        ...getNewVariables(
          expression.parameters,
          expression.valueType.parameterValueTypes,
          parentExpression?.type === "ApplicationExpression"
            ? parentExpression.arguments
            : undefined,
          context.fullFieldPath
        ),
      }}
      setVariableName={{
        ...setVariableName,
        ...Object.fromEntries(
          expression.parameters.map((parameter, index) => [
            parameter.id,
            getSetVariableNameFunction(expression, setExpression, index),
          ])
        ),
      }}
      valueTypeConstraint={bodyValueTypeConstraint}
      expression={expression.body}
      setExpression={(newExpression: Expression | null): void =>
        setExpression({
          ...expression,
          body: newExpression,
        })
      }
      lift={getFunctionExpressionBodyLiftFunction(expression, lift)}
      parentExpression={expression}
      setParentExpression={setExpression}
      fromParentFunction={{
        shouldStack: forBody.shouldStack,
        collapse: forBody.collapse,
      }}
      variableContext={variableContext}
      includeExpressionOption={includeExpressionOption}
      disableLift={parentExpression === null}
    />
  );
}

export function getFunctionExpressionBodyLiftFunction(
  expression: FunctionExpression,
  lift: LiftFunction
): LiftFunction {
  return (child): void => {
    if (
      expression.parameters.length !==
      expression.valueType.parameterValueTypes.length
    ) {
      return;
    }

    const oldVariableIdToNewVariable: {
      [oldVariableId: string]: Variable;
    } = Object.fromEntries(
      expression.parameters.map((parameter, index) => {
        const newVariable: Variable = {
          id: uniqueId(),
          name: parameter.name,
          valueType: expression.valueType.parameterValueTypes[index],
        };
        return [parameter.id, newVariable];
      })
    );
    const replaceResult = replaceOldVariables(
      oldVariableIdToNewVariable,
      child.argument
    );
    if (!replaceResult.newExpression) {
      throw new Error("replaceOldVariables returned null newExpression");
    }
    const replacedVariableIdToNewVariable: {
      [oldVariableId: string]: Variable;
    } = Object.fromEntries(
      Object.keys(replaceResult.replacedVariableIds).map(
        (replacedVariableId) => [
          replacedVariableId,
          oldVariableIdToNewVariable[replacedVariableId],
        ]
      )
    );
    if (child) {
      Object.assign(
        replacedVariableIdToNewVariable,
        child.replacedVariableIdToNewVariable
      );
    }

    function replaceArgument(
      variable: VariableExpression | ApplicationExpression
    ): FunctionExpression {
      const newExpression: FunctionExpression = {
        ...expression,
        body: child.replaceArgument(variable),
      };
      return newExpression;
    }
    // We never lift directly in an FunctionExpression; we let the parent
    // lift
    lift({
      argument: replaceResult.newExpression,
      replacedVariableIdToNewVariable,
      replaceArgument,
      newVariableName: child.newVariableName,
      isNew: child.isNew,
      keepInObjectField: child.keepInObjectField,
    });
  };
}

// Expensive
function replaceOldVariables(
  oldVariableIdToNewVariable: { [oldVariableId: string]: Variable },
  expression: Expression | null
): {
  newExpression: Expression | null;
  replacedVariableIds: { [variableId: string]: boolean };
} {
  type TMapResult = { [variableId: string]: boolean };
  const result = mapExpressionWithResult<TMapResult>(
    (expr) => {
      if (expr && expr.type === "VariableExpression") {
        const oldVariableId = expr.variableId;
        const newVariable = oldVariableIdToNewVariable[oldVariableId];
        if (newVariable) {
          const newExpression: VariableExpression = {
            id: uniqueId(),
            type: "VariableExpression",
            valueType: expr.valueType,
            variableId: newVariable.id,
          };
          return {
            newExpression,
            mapResult: { [oldVariableId]: true },
          };
        }
      }
      return {
        newExpression: expr,
        mapResult: {},
      };
    },
    (...results: TMapResult[]) => Object.assign({}, ...results),
    expression
  );
  return {
    newExpression: result.newExpression,
    replacedVariableIds: result.mapResult,
  };
}
