import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeExternalLinks from "rehype-external-links";
import React, { useState } from "react";
import { Info } from "@phosphor-icons/react";
import { ErrorBoundary } from "react-error-boundary";
import rehypeRaw from "rehype-raw";
import toWords from "@hypertune/shared-internal/src/toWords";
import CodeEditor, {
  Language,
  TabbedCodeEditor,
  languages,
} from "./CodeEditor";
import Label, { LabelType } from "./Label";
import Selector from "./Selector";
import {
  blackTextHex,
  intentPrimaryHex,
  interFontFamily,
  mediumFontSize,
  monospaceFontFamily,
} from "../lib/constants";
import Button from "./buttons/Button";
import getTextWidth from "../lib/generic/getTextWidth";
import CollapseDiv from "./animations/CollapseDiv";
import { CollapseIcon } from "./buttons/CollapseButton";

export default function MarkdownView({
  markdown,
}: {
  markdown: string;
}): React.ReactElement | null {
  return (
    <ErrorBoundary FallbackComponent={Fallback}>
      <Markdown
        components={{
          a: A,
          h1: H1,
          h2: H2,
          h3: H3,
          p: P,
          table: Table,
          thead: Thead,
          th: Th,
          tr: Tr,
          td: Td,
          ol: Ol,
          ul: Ul,
          li: Li,
          code: CodeView,
          pre: Pre,
          details: Details,
          summary: Summary,
        }}
        remarkPlugins={[remarkGfm]}
        rehypePlugins={[rehypeRaw, rehypeExternalLinks]}
      >
        {markdown}
      </Markdown>
    </ErrorBoundary>
  );
}

function A({
  href,
  children,
}: React.AnchorHTMLAttributes<HTMLAnchorElement>): React.ReactElement {
  return (
    <a
      href={href}
      className="text-intent-primary"
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </a>
  );
}

function H1({
  children,
}: React.HTMLAttributes<HTMLElement>): React.ReactElement {
  return (
    <H
      labelType="title1"
      wrapperClassName="mb-4 mt-7 first:mt-0"
      numberClassName="rounded-lg px-[7px]"
    >
      {children}
    </H>
  );
}

function H2({
  children,
}: React.HTMLAttributes<HTMLElement>): React.ReactElement {
  return (
    <H
      labelType="title3"
      wrapperClassName="mb-4 mt-4"
      numberClassName="rounded-md px-[6px]"
    >
      {children}
    </H>
  );
}

function H3({
  children,
}: React.HTMLAttributes<HTMLElement>): React.ReactElement {
  return (
    <H
      labelType="title3"
      wrapperClassName="mb-4"
      numberClassName="rounded-md px-[6px]"
    >
      {children}
    </H>
  );
}

function H({
  children,
  labelType,
  wrapperClassName,
  numberClassName,
}: React.HTMLAttributes<HTMLElement> & {
  labelType: LabelType;
  wrapperClassName: string;
  numberClassName: string;
}): React.ReactElement {
  const text = String(children).trim();
  const match = /^([0-9]+\.\s)?(.*)?$/.exec(text);

  return (
    <div className={`flex flex-row items-center gap-2 ${wrapperClassName}`}>
      {match && match[1] && (
        <div
          className={`border border-bd-darker bg-bg-medium ${numberClassName}`}
        >
          <Label type={labelType} className="text-tx-default">
            {match[1].trim().slice(0, -1)}
          </Label>
        </div>
      )}
      <Label type={labelType} className="text-tx-default">
        {match && match[2] ? match[2] : text}
      </Label>
    </div>
  );
}

function P({
  children,
}: React.HTMLAttributes<HTMLElement>): React.ReactElement {
  return (
    <p
      className="mb-4 whitespace-pre-wrap text-tx-muted"
      style={{ fontFamily: interFontFamily }}
    >
      {children}
    </p>
  );
}

function Table({
  children,
}: React.TableHTMLAttributes<HTMLTableElement>): React.ReactElement {
  return (
    <div>
      <table className="mb-4">{children}</table>
    </div>
  );
}

function Thead({
  children,
}: React.HTMLAttributes<HTMLTableSectionElement>): React.ReactElement {
  return <thead className="border-t">{children}</thead>;
}

function Tr({
  children,
}: React.HTMLAttributes<HTMLTableRowElement>): React.ReactElement {
  return <tr className="border-t">{children}</tr>;
}

function Th({
  children,
}: React.ThHTMLAttributes<HTMLTableHeaderCellElement>): React.ReactElement {
  return <th className="px-2 py-1 text-left">{children}</th>;
}

function Td({
  children,
}: React.TdHTMLAttributes<HTMLTableDataCellElement>): React.ReactElement {
  return <td className="px-2 py-1 text-left">{children}</td>;
}

function Ol({
  children,
}: React.OlHTMLAttributes<HTMLOListElement>): React.ReactElement {
  return (
    <ol
      className="mb-4 whitespace-normal pl-[16px] text-tx-muted"
      style={{ listStyleType: "decimal" }}
    >
      {children}
    </ol>
  );
}

function Ul({
  children,
}: React.OlHTMLAttributes<HTMLUListElement>): React.ReactElement {
  return (
    <ul
      className="mb-4 whitespace-normal pl-[16px] text-tx-muted"
      style={{ listStyleType: "disc" }}
    >
      {children}
    </ul>
  );
}

function Li({
  children,
}: React.LiHTMLAttributes<HTMLLIElement>): React.ReactElement {
  return (
    <li className="mt-2 pl-1 first:mt-0">
      {React.Children.map(children, (child) =>
        child &&
        typeof child === "object" &&
        "type" in child &&
        (child.type === Ol || child.type === Ul) ? (
          <div className="mt-2">{child}</div>
        ) : (
          child
        )
      )}
    </li>
  );
}

function Pre({
  children,
}: React.HTMLAttributes<HTMLPreElement>): React.ReactElement {
  return <>{children} </>;
}

function CodeView(
  props: React.HTMLAttributes<HTMLElement>
): React.ReactElement {
  const { children, className } = props;
  const match = /^language-(\w+)(:(.+))?$/.exec(className || "");

  if (!match) {
    return (
      <>
        <code className="bg-bg-pressed">{children}</code>{" "}
      </>
    );
  }

  switch (match[1]) {
    case "info":
      return <InfoBlock text={String(children)} />;
    case "tabs":
      return (
        <Tabs
          tabNames={match[3]
            .split("%%%")
            .map((tab) => tab.split("_").join(" "))}
          tabContents={String(children).split("%%%")}
        />
      );
    case "codetabs":
      if (match[3]) {
        const [language, ...tabNames] = match[3]
          .split("%%%")
          .map((tab) => toWords(tab).join(" "));
        const tabContents = String(children)
          .split("%%%")
          .map((code) => code.trim());

        if (!isValidLanguage(language)) {
          break;
        }

        return (
          <div className="mb-4">
            <TabbedCodeEditor
              tabs={tabNames.map((name, index) => {
                return {
                  label: name,
                  value: tabContents[index],
                };
              })}
              language={language as Language}
              className="rounded-xl border bg-bg-light"
            />
          </div>
        );
      }
      break;
    default:
      if (isValidLanguage(match[1])) {
        const code = String(children).trimEnd();
        return (
          <div className="mb-4">
            <CodeEditor
              code={code}
              readOnly
              setCode={() => {
                // Dummy
              }}
              language={match[1] as Language}
              fileName={match[3]}
              showButtons="on-hover"
              className="overflow-hidden rounded-xl border bg-bg-light"
              wrapperClassName="w-full overflow-x-auto"
              style={{
                minWidth: Math.max(
                  ...code
                    .split("\n")
                    .map(
                      (line) =>
                        getTextWidth(
                          monospaceFontFamily,
                          mediumFontSize,
                          line
                        ) + 25
                    )
                ),
              }}
            />
          </div>
        );
      }
  }
  return <code className="bg-bg-pressed">{children}</code>;
}

function Tabs({
  tabNames,
  tabContents,
}: {
  tabNames: string[];
  tabContents: string[];
}): React.ReactElement | null {
  const [selected, setSelected] = useState<string>(tabNames[0] as string);
  const selectedIndex = tabNames.indexOf(selected);

  return (
    <>
      <Selector
        options={tabNames}
        values={tabNames}
        selectedValue={selected}
        onSelectValue={setSelected}
        style={{ marginBottom: 16 }}
        buttonStyle={{ paddingLeft: 10, paddingRight: 10 }}
      />
      {selectedIndex !== -1 && (
        <MarkdownView markdown={tabContents[selectedIndex]} />
      )}
    </>
  );
}

function InfoBlock({ text }: { text: string }): React.ReactElement | null {
  return (
    <div className="mb-4 flex flex-row gap-2 rounded-xl bg-intent-primary/10 p-2">
      <span className="mt-[2px]">
        <Info weight="bold" color={intentPrimaryHex} />
      </span>
      <div className="-mb-4">
        <MarkdownView markdown={text} />
      </div>
    </div>
  );
}

function Summary({
  children,
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLElement>,
  HTMLElement
>): React.ReactElement | null {
  return (
    <H
      labelType="title1"
      wrapperClassName=""
      numberClassName="rounded-lg px-[7px]"
    >
      {children}
    </H>
  );
}

function Details({
  open,
  children,
}: React.DetailsHTMLAttributes<HTMLDetailsElement>): React.ReactElement | null {
  const childrenArray = React.Children.toArray(children);
  const nameIndex = childrenArray.findIndex((child) => {
    return (
      typeof child === "object" && "key" in child && child.key === ".$summary-0"
    );
  });
  const name = nameIndex !== -1 ? childrenArray[nameIndex] : null;
  if (nameIndex !== -1) {
    childrenArray.splice(nameIndex, 1);
  }
  const [isOpen, setIsOpen] = useState<boolean>(open || false);

  return (
    <div
      className={`mt-7 flex flex-col gap-2 first:mt-0 ${!isOpen ? "-mb-2" : ""}`}
    >
      <div>
        <button
          type="button"
          className="hover:filter-muted flex flex-row items-baseline gap-2 hover:text-bd-light"
          onClick={() => setIsOpen(!isOpen)}
        >
          <CollapseIcon
            size={14}
            color={blackTextHex}
            isOpen={isOpen}
            className="-mt-px"
          />
          {name}
        </button>
      </div>

      <CollapseDiv isOpen={isOpen}>
        <div className="mt-2 flex w-full flex-col">{childrenArray}</div>
      </CollapseDiv>
    </div>
  );
}

function Fallback({
  error,
  resetErrorBoundary,
}: {
  error: Error;
  resetErrorBoundary(): void;
}): React.ReactElement {
  return (
    <div className="flex flex-col items-start gap-2">
      Error rendering markdown: {error.message}
      <Button text="Try again" weight="minimal" onClick={resetErrorBoundary} />
    </div>
  );
}

function isValidLanguage(language: string): boolean {
  return languages.some((validLanguage) => language === validLanguage);
}
