import React, { useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { DbBillingData, toStartCase } from "@hypertune/shared-internal";
import {
  BusinessesDocument,
  BusinessesQuery,
  Plan,
  UpdateBusinessInput,
  useBusinessesQuery,
  useUpdateBusinessMutation,
} from "../../generated/graphql";
import BusinessPage from "./BusinessPage";
import MutableText from "../../components/input/MutableText";

import VercelEdgeConfig from "./vercel/VercelEdgeConfig";
import { vercelAuthCodeSearchParamKey } from "../../lib/constants";
import CardGroup, { CardGroupItem } from "../../components/CardGroup";
import Label from "../../components/Label";
import VerifyAndInviteEmailButton from "./VerifyAndInviteEmailButton";
import EditDomainsButton from "./EditDomainsButton";
import ErrorMessageCard from "../../components/ErrorMessageCard";
import { canEditBusiness } from "../../lib/query/rolePermissions";
import RoleDropDown from "./team/RoleDropDown";
import { useHypertune } from "../../generated/hypertune.react";
import VercelEdgeConfigSetup from "./vercel/VercelEdgeConfigSetup";
import Button from "../../components/buttons/Button";
import { formatRawDate } from "../../lib/generic/formatDate";
import Tag from "../../components/Tag";

export default function SettingsPage(): React.ReactElement {
  const location = useLocation();
  useEffect(() => {
    document.title = "Settings - Hypertune";
  }, []);

  const { loading, error, data } = useBusinessesQuery();

  const [searchParams] = useSearchParams();
  const showVercelEdgeConfigSetup =
    !!searchParams.get(vercelAuthCodeSearchParamKey) ||
    !!searchParams.get("vercel-setup");
  const compact = location.pathname.endsWith("vercel-edge-config");

  if (showVercelEdgeConfigSetup) {
    return <VercelEdgeConfigSetup data={data?.primaryBusiness} />;
  }

  return (
    <BusinessPage compact={compact}>
      {!compact && (
        <Label type="title1" className="mb-5">
          {data?.primaryBusiness?.business.type === "Personal"
            ? "Account settings"
            : "Settings"}
        </Label>
      )}
      {loading ? (
        <>
          <CardGroup
            layout="list"
            cardLayout="horizontal"
            loadingCount={2}
            className="mb-7"
            cards={[]}
          />
          <VercelEdgeConfig.LoadingSkeleton />
        </>
      ) : error ? (
        <ErrorMessageCard error={error} />
      ) : data ? (
        !data.primaryBusiness ? (
          <Label type="small-body" className="pt-[9.5px] text-tx-muted">
            No team selected.
          </Label>
        ) : compact ? (
          <VercelEdgeConfig data={data.primaryBusiness} />
        ) : (
          <>
            {data.primaryBusiness.business.type === "Personal" ? (
              <AccountSettings me={data.me} />
            ) : (
              <BusinessSettings data={data.primaryBusiness} />
            )}
            <VercelEdgeConfig data={data.primaryBusiness} />
          </>
        )
      ) : null}
    </BusinessPage>
  );
}

function AccountSettings({
  me,
}: {
  me: NonNullable<BusinessesQuery["me"]>;
}): React.ReactElement {
  return (
    <CardGroup
      className="mb-7"
      layout="list"
      cardLayout="horizontal"
      cards={[
        {
          key: "name",
          className: "items-start",
          children: (
            <>
              <Label type="title3">Full name</Label>
              {me.displayName}
            </>
          ),
        },
        {
          key: "email",
          className: "items-start",
          children: (
            <>
              <Label type="title3">Email address</Label>
              <div className="flex flex-col items-end gap-2">
                <Label type="title4">{me.email}</Label>
                {!me.emailVerified && <VerifyAndInviteEmailButton />}
              </div>
            </>
          ),
        },
      ]}
    />
  );
}

function BusinessSettings({
  data,
}: {
  data: NonNullable<BusinessesQuery["primaryBusiness"]>;
}): React.ReactElement {
  const navigate = useNavigate();
  const [updateBusiness, { loading }] = useUpdateBusinessMutation({
    refetchQueries: [BusinessesDocument],
    awaitRefetchQueries: true,
  });
  const readOnly = !canEditBusiness(data.role);

  const hypertune = useHypertune();
  const roleManagementEnabled = hypertune
    .features()
    .teamRolesEnabled({ fallback: false });

  const billingData: DbBillingData = JSON.parse(
    data.business.billingDataJson ?? "{}"
  );

  return (
    <CardGroup
      className="mb-7"
      layout="list"
      cardLayout="horizontal"
      cards={[
        {
          key: "name",
          className: "items-start",
          children: (
            <>
              <Label type="title3">Team name</Label>
              <MutableText
                text={data.business.name}
                setText={async (newText) => {
                  const input: UpdateBusinessInput = {
                    id: data.business.id,
                    name: newText,
                  };
                  try {
                    await updateBusiness({ variables: { input } });
                  } catch (error) {
                    console.error("updateBusiness error:", error);
                  }
                }}
                showPencil
                readOnly={readOnly}
              />
            </>
          ),
        },
        ...(roleManagementEnabled
          ? ([
              {
                key: "default-role",
                className: "items-start",
                children: (
                  <>
                    <Label type="title3">Default new member role</Label>
                    <RoleDropDown
                      role={data.business.defaultNewUserRole}
                      changeRole={(newRole) =>
                        updateBusiness({
                          variables: {
                            input: {
                              id: data.business.id,
                              defaultNewUserRole: newRole,
                            },
                          },
                        })
                      }
                      loading={loading}
                      readOnly={readOnly}
                    />
                  </>
                ),
              },
            ] as CardGroupItem[])
          : []),
        {
          key: "plan",
          className: "items-start",
          children: (
            <>
              <Label type="title3">Plan</Label>
              <div className="flex flex-col items-end gap-2">
                <div className="flex flex-row items-center gap-2">
                  <Label type="title4">
                    {hypertune
                      .plansContent()
                      .planContent({
                        args: { plan: data.business.plan },
                      })
                      .name({ fallback: toStartCase(data.business.plan) })}
                  </Label>
                  {data.business.plan !== Plan.Free &&
                    billingData.subscriptionEndsAt && (
                      <Tag
                        intent="warning"
                        text={`Ends at ${formatRawDate(billingData.subscriptionEndsAt)}`}
                      />
                    )}
                </div>
                {!readOnly && (
                  <div className="flex w-full flex-row justify-end">
                    <Button
                      text="Manage"
                      intent="primary"
                      weight="outlined"
                      onClick={() => navigate("/plans")}
                    />
                  </div>
                )}
              </div>
            </>
          ),
        },
        {
          key: "domains",
          className: "items-start",
          children: (
            <>
              <Label type="title3">Auto-join domains</Label>
              <div className="flex flex-col gap-2">
                {data.business.domains.length > 0 && (
                  <Label type="title4" className="text-end">
                    {data.business.domains.join(", ")}
                  </Label>
                )}
                {!readOnly && (
                  <div className="flex w-full flex-row justify-end">
                    <EditDomainsButton
                      businessId={data.business.id}
                      plan={data.business.plan}
                      domains={data.business.domains}
                    />
                  </div>
                )}
              </div>
            </>
          ),
        },
      ]}
    />
  );
}
