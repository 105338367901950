import React, { useCallback, useEffect, useMemo, useRef } from "react";
import {
  fieldPathSeparator,
  Expression,
  Schema,
  mapExpressionWithResult,
} from "@hypertune/sdk/src/shared";
import { NavigateOptions } from "react-router-dom";
import { ArrowDown, ArrowUp, Plus } from "@phosphor-icons/react";
import {
  queryObjectTypeName,
  rootFieldName,
  variablePathSuffix,
} from "@hypertune/shared-internal";
import { ValueTypeConstraint } from "@hypertune/shared-internal/src/expression/types";
import { bottomScrollSpacing } from "../../../lib/constants";
import { ExpressionControlContext } from "../../../lib/types";
import SidebarItem from "../../../components/SidebarItem";
import ExpressionTree from "./ExpressionTree";
import ValueTypeConstraintIcon from "./ValueTypeConstraintIcon";

import Folder from "../../../components/icons/Folder";
import matchesSearch from "../../../lib/generic/matchesSearch";
import {
  ExpressionNode,
  ExpressionNodeMap,
  SelectedExpressionNode,
  findItemInTree,
} from "./toTree";
import { iconColor } from "../../../components/icons/icons";
import ExpressionDetail, {
  expressionNodeHasMarkdown,
} from "./ExpressionDetail";
import SidebarContainer from "../SidebarContainer";
import NewFlagButton from "../controls/NewFlagButton";
import ExpressionViewTopBar from "./ExpressionViewTopBar";
import {
  useLogicSelectedFieldPath,
  useLogicSetSelectedFieldPath,
} from "../logicHooks";

import getDefaultFieldPathAndTopLevelEnumTypeName from "../../../lib/expression/getDefaultFieldPathAndTopLevelEnumTypeName";
import Button from "../../../components/buttons/Button";
import {
  decreaseItemIndex,
  increaseItemIndex,
} from "../../../lib/generic/array";
import getListExpressionItemsAndSetItemsFunction from "../../../lib/expression/getListExpressionItemsAndSetItemsFunction";
import useSearchParamsState from "../../../app/useSearchParamsState";

export const viewCompleteTreeSuffix = `${fieldPathSeparator}--view--all`;

const searchTextQueryParamKey = "logic_search";

const valueTypeConstraint: ValueTypeConstraint = {
  type: "ObjectValueTypeConstraint",
  objectTypeName: queryObjectTypeName,
};

export default function ExpressionView({
  isVisible,
  hasError,
  context,
  schema,
  expressionTree,
  expression,
  setExpression,
  defaultFieldPath,
}: {
  isVisible: boolean;
  hasError: boolean;
  context: ExpressionControlContext;
  schema: Schema;
  expressionTree: ExpressionNodeMap;
  expression: Expression;
  setExpression: (newExpression: Expression | null) => void;
  defaultFieldPath: string[];
}): React.ReactElement {
  const { readOnly } = context;
  const contentDivRef = useRef<HTMLDivElement>(null);
  const [searchText, setSearchText] = useSearchParamsState(
    searchTextQueryParamKey,
    ""
  );
  const expressionStringsMap = useMemo(() => {
    const stringsMap = {};
    collectExpressionStrings(stringsMap, expressionTree[rootFieldName]);

    return stringsMap;
  }, [expressionTree]);

  const defaultFieldPathString = defaultFieldPath.join(fieldPathSeparator);
  const viewCompleteTreePath = defaultFieldPathString + viewCompleteTreeSuffix;

  const selectedFieldPath = useLogicSelectedFieldPath(defaultFieldPathString);
  // eslint-disable-next-line no-underscore-dangle
  const _setSelectedFieldPath = useLogicSetSelectedFieldPath();
  const setSelectedFieldPath = useCallback(
    (newSelectedFieldPath: string, options?: NavigateOptions) => {
      if (selectedFieldPath !== newSelectedFieldPath) {
        const el = contentDivRef.current;
        if (el) {
          el.scrollTo({ top: 0 });
        }
      }
      _setSelectedFieldPath(newSelectedFieldPath, options);
    },
    [_setSelectedFieldPath, contentDivRef, selectedFieldPath]
  );

  console.debug("Schema", context.commitContext.schema);
  console.debug("Tree", expressionTree);

  const isFullTreeViewSelected = selectedFieldPath?.endsWith(
    viewCompleteTreeSuffix
  );

  const selected = useMemo<SelectedExpressionNode>(
    () =>
      isFullTreeViewSelected
        ? {
            item: {
              context,
              fieldLabel: "",
              fullFieldPath: "",
              index: null,
              hasActions: false,
              hasError,
              title: "",
              search: "",
              childExpressions: expressionTree,
              variables: {},
              setVariableName: {},
              valueTypeConstraint,
              expression,
              setExpression,
              lift: () => {
                // Dummy
              },
              includeExpressionOption: () => true,
            },
            parent: null,
          }
        : findItemInTree(
            expressionTree,
            defaultFieldPathString,
            null,
            selectedFieldPath?.split(fieldPathSeparator) ?? []
          ),
    [
      isFullTreeViewSelected,
      context,
      hasError,
      expressionTree,
      expression,
      setExpression,
      defaultFieldPathString,
      selectedFieldPath,
    ]
  );
  console.debug("Selected", selected);

  useEffect(() => {
    if (!isVisible) {
      return;
    }
    if (
      selected.item &&
      selected.item.fullFieldPath !== defaultFieldPathString
    ) {
      document.title = `${selected.item.fieldLabel} - Hypertune`;
      return;
    }
    document.title = "Logic - Hypertune";
  }, [defaultFieldPathString, isVisible, selected]);
  useEffect(() => {
    if (!selectedFieldPath) {
      return;
    }
    if (selectedFieldPath.endsWith(viewCompleteTreeSuffix)) {
      if (selectedFieldPath !== viewCompleteTreePath) {
        // Correct view complete tree path.
        setSelectedFieldPath(viewCompleteTreePath);
      }
      return;
    }
    if (!selected.item) {
      if (
        findItemInTree(
          expressionTree,
          defaultFieldPathString,
          null,
          defaultFieldPath
        ).item === null
      ) {
        // If default field is not in the tree then reset it based on current expression.
        const { defaultFieldPath: newDefaultFieldPath } =
          getDefaultFieldPathAndTopLevelEnumTypeName(schema, expression);

        setSelectedFieldPath(newDefaultFieldPath.join(fieldPathSeparator));
      }
    }
    // We don't want changes to selectedField to trigger this function
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  return (
    <div
      style={{
        flexGrow: 1,
        display: isVisible ? "flex" : "none",
        flexDirection: "row",
        alignItems: "stretch",
        height: "100%",
        width: "100%",
      }}
      className="bg-bg-light bg-dotted"
    >
      <SidebarContainer
        searchText={searchText}
        setSearchText={setSearchText}
        actions={
          !readOnly ? (
            <NewFlagButton defaultFieldPath={defaultFieldPath} />
          ) : undefined
        }
      >
        <ExpressionNodeListRows
          readOnly={readOnly}
          expressionStringsMap={expressionStringsMap}
          parentExpressionNode={null}
          tree={
            findItemInTree(
              expressionTree,
              defaultFieldPathString,
              null,
              defaultFieldPath
            )?.item?.childExpressions || {}
          }
          searchText={searchText}
          selectedFieldPath={selectedFieldPath || ""}
          setSelectedFieldPath={setSelectedFieldPath}
        />
        {!searchText && (
          <SidebarItem
            icon={<Folder />}
            title="View complete tree"
            className="px-3 py-[11px]"
            onClick={() => {
              setSelectedFieldPath(viewCompleteTreePath);
            }}
            hasError={hasError}
            isSelected={isFullTreeViewSelected}
          />
        )}
      </SidebarContainer>
      {selectedFieldPath &&
      selected.item &&
      selected.item.fullFieldPath !== defaultFieldPathString ? (
        <div className="flex h-full w-full flex-col overflow-hidden">
          {!isFullTreeViewSelected && (
            <ExpressionViewTopBar
              readOnly={readOnly}
              schema={schema}
              selectedExpressionNode={selected.item}
              selectedExpressionNodeParent={
                selected.parent ||
                findItemInTree(
                  expressionTree,
                  defaultFieldPathString,
                  null,
                  defaultFieldPath
                )?.item
              }
              selectedFieldPath={selectedFieldPath}
              setSelectedFieldPath={setSelectedFieldPath}
            />
          )}
          <div className="flex h-full w-full flex-row justify-between overflow-hidden">
            <div
              ref={contentDivRef}
              style={{
                flexGrow: 1,
                overflow: "auto",
                paddingBottom: bottomScrollSpacing,
              }}
            >
              <ExpressionTree
                context={{
                  ...context,
                  ...selected.item.context,
                  fullFieldPath: isFullTreeViewSelected
                    ? ""
                    : selectedFieldPath,
                }}
                variables={selected.item.variables}
                setVariableName={selected.item.setVariableName}
                valueTypeConstraint={selected.item.valueTypeConstraint}
                expression={selected.item.expression}
                setExpression={selected.item.setExpression}
                parentExpression={selected.parent?.expression || null}
                setParentExpression={selected.parent?.setExpression}
                lift={({
                  argument,
                  replacedVariableIdToNewVariable,
                  replaceArgument,
                  newVariableName,
                  isNew,
                  keepInObjectField,
                }) => {
                  if (!selected.item) {
                    return;
                  }
                  selected.item.lift({
                    argument,
                    replacedVariableIdToNewVariable,
                    replaceArgument,
                    newVariableName,
                    isNew,
                    keepInObjectField,
                  });
                  if (selectedFieldPath && newVariableName) {
                    const newPath = selectedFieldPath.split(fieldPathSeparator);

                    if (
                      selected.parent?.expression?.valueType.type ===
                        "FunctionValueType" &&
                      (selected.item.expression?.type ===
                        "FunctionExpression" ||
                        selected.item.expression?.type ===
                          "ApplicationExpression")
                    ) {
                      // Don't shorten the path when extracting variables inside of a nested object.
                      newPath[newPath.length - 1] =
                        newVariableName + variablePathSuffix;
                    } else if (isNew) {
                      newPath[newPath.length - 1] =
                        newVariableName + variablePathSuffix;
                    } else if (newPath.length > 1) {
                      newPath.splice(newPath.length - 2, 1);
                    }

                    setSelectedFieldPath(newPath.join(fieldPathSeparator));
                  }
                }}
                variableContext={
                  selected.item.variableContext
                    ? {
                        name: selected.item.variableContext.name,
                        rename: selected.item.variableContext.rename,
                        drop: () => {
                          selected.item?.variableContext?.drop();
                          setSelectedFieldPath(defaultFieldPathString);
                        },
                      }
                    : undefined
                }
                includeExpressionOption={selected.item.includeExpressionOption}
              />
            </div>
            {!isFullTreeViewSelected &&
              ((!selected.item.variableContext && selected.item.hasActions) ||
                expressionNodeHasMarkdown(
                  selected.item.expression,
                  selected.item.fieldLabel
                )) && (
                <ExpressionDetail
                  readOnly={readOnly}
                  expression={selected.item.expression}
                  fieldName={selected.item.fieldLabel}
                  variables={selected.item.variables}
                  schema={context.commitContext.schema}
                  topLevelExpression={expression}
                  onDelete={() => setSelectedFieldPath(defaultFieldPathString)}
                />
              )}
          </div>
        </div>
      ) : null}
    </div>
  );
}

function ExpressionNodeListRows({
  readOnly,
  expressionStringsMap,
  parentExpressionNode,
  tree,
  searchText,
  selectedFieldPath,
  setSelectedFieldPath,
}: {
  readOnly: boolean;
  expressionStringsMap: Record<string, string[]>;
  parentExpressionNode: ExpressionNode | null;
  tree: ExpressionNodeMap;
  searchText: string;
  selectedFieldPath: string | null;
  setSelectedFieldPath: (newSelectedFieldPath: string) => void;
}): React.ReactElement | null {
  return (
    <>
      {Object.entries(tree)
        .filter(([, item]) =>
          matchesTreeSearch(expressionStringsMap, searchText, item)
        )
        .sort(([, item1], [, item2]) => {
          // Ensure variables always appear first.
          const item1IsVar = !!item1.variableContext;
          const item2IsVar = !!item2.variableContext;
          if (item1IsVar === item2IsVar) {
            return 0;
          }
          return item1IsVar ? -1 : 1;
        })
        .map(([, item]) => {
          return (
            <ExpressionNodeRow
              readOnly={readOnly}
              expressionStringsMap={expressionStringsMap}
              parentExpressionNode={parentExpressionNode}
              expressionNode={item}
              searchText={searchText}
              selectedFieldPath={selectedFieldPath}
              setSelectedFieldPath={setSelectedFieldPath}
            />
          );
        })}
    </>
  );
}

function ExpressionNodeRow({
  readOnly,
  expressionStringsMap,
  parentExpressionNode,
  expressionNode,
  searchText,
  selectedFieldPath,
  setSelectedFieldPath,
}: {
  readOnly: boolean;
  expressionStringsMap: Record<string, string[]>;
  parentExpressionNode: ExpressionNode | null;
  expressionNode: ExpressionNode;
  searchText: string;
  selectedFieldPath: string | null;
  setSelectedFieldPath: (newSelectedFieldPath: string) => void;
}): React.ReactElement {
  const newChild = expressionNode.newChildExpression;
  const showNewChild = newChild && !readOnly;
  const hasChildren = expressionNode.childExpressions !== null;
  const isVariable = !!expressionNode.variableContext;
  const isSelected = selectedFieldPath === expressionNode.fullFieldPath;

  const { items: parentItems, setItems: setParentItems } =
    getListExpressionItemsAndSetItemsFunction(
      parentExpressionNode?.expression ?? null
    );

  return (
    <SidebarItem
      icon={
        <ValueTypeConstraintIcon
          isVariable={isVariable}
          hasChildren={hasChildren}
          valueTypeConstraint={expressionNode.valueTypeConstraint}
        />
      }
      title={expressionNode.fieldLabel}
      className={`px-3 ${hasChildren || showNewChild ? "py-[9px]" : "py-[11px]"}`}
      onClick={() => setSelectedFieldPath(expressionNode.fullFieldPath)}
      isSelected={isSelected}
      hasError={expressionNode.hasError}
      actions={[
        ...(parentItems && expressionNode.index !== null
          ? [
              <>
                <Button
                  intent={isSelected ? "primary" : "neutral"}
                  weight="outlined"
                  size="x-small"
                  disabled={expressionNode.index <= 0}
                  icon={
                    <ArrowUp
                      weight="bold"
                      size={12}
                      color={iconColor(isSelected)}
                    />
                  }
                  onClick={() => {
                    const newItems = decreaseItemIndex(
                      parentItems ?? [],
                      expressionNode.index ?? 0
                    );
                    parentExpressionNode?.setExpression(
                      setParentItems(newItems)
                    );
                  }}
                />
                <Button
                  weight="outlined"
                  size="x-small"
                  disabled={expressionNode.index >= parentItems.length - 1}
                  icon={
                    <ArrowDown
                      weight="bold"
                      size={12}
                      color={iconColor(!!isSelected)}
                    />
                  }
                  onClick={() => {
                    const newItems = increaseItemIndex(
                      parentItems,
                      expressionNode.index ?? 0
                    );
                    parentExpressionNode?.setExpression(
                      setParentItems(newItems)
                    );
                  }}
                />
              </>,
            ]
          : []),

        ...(showNewChild
          ? [
              <Button
                intent={isSelected ? "primary" : "neutral"}
                weight="outlined"
                size="x-small"
                icon={
                  <Plus
                    weight="bold"
                    size={12}
                    color={iconColor(!!isSelected)}
                  />
                }
                onClick={() => {
                  const newChildId = newChild();
                  if (newChildId) {
                    setSelectedFieldPath(
                      `${expressionNode.fullFieldPath}${fieldPathSeparator}${newChildId}`
                    );
                  }
                }}
              />,
            ]
          : []),
      ]}
      showCollapseToggle={!!newChild}
    >
      {expressionNode.childExpressions ? (
        <ExpressionNodeListRows
          readOnly={readOnly}
          expressionStringsMap={expressionStringsMap}
          parentExpressionNode={expressionNode}
          tree={expressionNode.childExpressions}
          searchText={searchText}
          selectedFieldPath={selectedFieldPath}
          setSelectedFieldPath={setSelectedFieldPath}
        />
      ) : null}
    </SidebarItem>
  );
}

function matchesTreeSearch(
  stringsMap: { [fullFieldPath: string]: string[] },
  searchText: string,
  item: ExpressionNode
): boolean {
  return (
    matchesSearch(searchText, [
      item.fullFieldPath,
      item.fieldLabel,
      ...(stringsMap[item.fullFieldPath] ?? []),
    ]) ||
    (!!item.childExpressions &&
      Object.entries(item.childExpressions).some(([, childItem]) => {
        return matchesTreeSearch(stringsMap, searchText, childItem);
      }, false))
  );
}

function collectExpressionStrings(
  stringsMap: { [fullFieldPath: string]: string[] },
  item: ExpressionNode
): void {
  if (item.childExpressions) {
    Object.values(item.childExpressions).forEach((childItem) => {
      collectExpressionStrings(stringsMap, childItem);
    });
    stringsMap[item.fullFieldPath] = Object.values(
      item.childExpressions
    ).flatMap((childItem) => stringsMap[childItem.fullFieldPath] ?? []);
    return;
  }
  stringsMap[item.fullFieldPath] = mapExpressionWithResult<string[]>(
    (expr) => {
      if (
        expr?.type === "StringExpression" ||
        expr?.type === "EnumExpression"
      ) {
        return { newExpression: expr, mapResult: [expr.value] };
      }
      return { newExpression: expr, mapResult: [] };
    },
    (...results: string[][]) => results.flat(),
    item.expression
  ).mapResult;
}
