import { nullThrows } from "@hypertune/sdk/src/shared";
import { Schema, ValueType } from "@hypertune/sdk/src/shared/types";
import {
  changeObjectRole,
  cloneObject,
  getNextSchemaName,
  toStartCase,
} from "@hypertune/shared-internal";
import { TypeReferencesMap } from "./schemaHooks";
import { combineValueTypes } from "./typeEditor/object/ValueTypeSelector";
import { objectRolesAreCompatible } from "./getValueTypeMessage";

export default function getNewSchemaAndValueType({
  schema,
  objectTypeName,
  valueTypes,
  typeReferences,
}: {
  schema: Schema;
  objectTypeName: string;
  valueTypes: ValueType[];
  typeReferences: TypeReferencesMap;
}): {
  newSchema: Schema;
  newValueType: ValueType;
} {
  let newSchema = schema;
  const wrapperTypes = valueTypes.slice(0, -1);
  const leafValueType = nullThrows(valueTypes.at(-1), "Missing value type.");

  if (leafValueType.type === "ObjectValueType") {
    const parentObjectRole = schema.objects[objectTypeName].role;
    const fieldObjectRole = schema.objects[leafValueType.objectTypeName].role;
    if (!objectRolesAreCompatible(parentObjectRole, fieldObjectRole)) {
      if (
        typeReferences[leafValueType.objectTypeName] &&
        typeReferences[leafValueType.objectTypeName].size > 0
      ) {
        // Has references so we clone the object.
        const clonedTypeName = getNextSchemaName(
          schema,
          `${leafValueType.objectTypeName}${parentObjectRole === "output" ? "Object" : toStartCase(parentObjectRole)}`
        );
        newSchema = cloneObject(
          newSchema,
          leafValueType.objectTypeName,
          clonedTypeName,
          parentObjectRole as "input" | "output" | "event"
        );
        leafValueType.objectTypeName = clonedTypeName;
      } else {
        // Doesn't have references so we just change the role.
        newSchema = changeObjectRole(
          newSchema,
          leafValueType.objectTypeName,
          parentObjectRole as "input" | "output" | "event"
        );
      }
    }
  }

  return {
    newSchema,
    newValueType: combineValueTypes([...wrapperTypes, leafValueType]),
  };
}
