import {
  ApplicationExpression,
  EnumSwitchExpression,
  EnumValueSchema,
  Expression,
  Schema,
  VariableExpression,
} from "@hypertune/sdk/src/shared";
import getConstraintFromValueType from "@hypertune/shared-internal/src/expression/constraint/getConstraintFromValueType";
import isValueTypeValid from "@hypertune/shared-internal/src/expression/isValueTypeValid";
import {
  ValueTypeConstraint,
  VariableMap,
} from "@hypertune/shared-internal/src/expression/types";
import toStartCase from "@hypertune/shared-internal/src/toStartCase";
import createApplication from "../../../lib/expression/createApplication";
import getTextLabel from "../../../lib/generic/getTextLabel";
import {
  ExpressionControlContext,
  IncludeExpressionOptionFunction,
  LiftFunction,
  SelectedItem,
} from "../../../lib/types";
import ExpressionControl from "./ExpressionControl";
import LabeledExpressionControlList, {
  LabeledExpressionControlListItemControlType,
} from "./LabeledExpressionControlList";
import isReadOnly from "../../../lib/expression/isReadOnly";
import { liftPermissionsDeniedErrorMessage } from "../../../lib/constants";
import DeleteButton from "../../../components/buttons/DeleteButton";

export default function EnumSwitchExpressionControl({
  context,
  variables,
  setVariableName,
  expression,
  setExpression,
  includeExpressionOption,
}: {
  context: ExpressionControlContext;
  variables: VariableMap;
  setVariableName: { [variableId: string]: (newVariableName: string) => void };
  expression: EnumSwitchExpression;
  setExpression: (newExpression: Expression | null) => void;
  includeExpressionOption: IncludeExpressionOptionFunction;
}): React.ReactElement {
  const childValueTypeConstraint: ValueTypeConstraint = isValueTypeValid(
    context.commitContext.schema,
    expression.valueType
  )
    ? getConstraintFromValueType(expression.valueType)
    : { type: "ErrorValueTypeConstraint" };

  const { schemaEnumValues, casePosition } =
    getEnumSwitchExpressionSchemaValuesAndCasePositions(
      context.commitContext.schema,
      expression
    );

  const readOnly = isReadOnly(context);
  const includeExpressionOptionForCases =
    getEnumSwitchCaseIncludeExpressionOptionFunction(
      expression,
      includeExpressionOption
    );

  return (
    <LabeledExpressionControlList
      useArrow
      items={[
        {
          type: LabeledExpressionControlListItemControlType,
          label: getTextLabel("Control"),
          intent: context.expressionIdToIntent?.[expression.id] ?? "neutral",
          options: [],
          expressionControl: (
            <ExpressionControl
              context={context}
              variables={variables}
              setVariableName={setVariableName}
              valueTypeConstraint={{ type: "AnyEnumValueTypeConstraint" }}
              expression={expression.control}
              setExpression={(newExpression: Expression | null): void => {
                setExpression({
                  ...expression,
                  control: newExpression,
                });
              }}
              lift={(child): void => {
                if (readOnly) {
                  // eslint-disable-next-line no-alert
                  alert(liftPermissionsDeniedErrorMessage);
                  return;
                }
                function replaceArgument(
                  variable: VariableExpression | ApplicationExpression
                ): EnumSwitchExpression {
                  const newExpression: EnumSwitchExpression = {
                    ...expression,
                    control: child.replaceArgument(variable),
                  };
                  return newExpression;
                }
                const applicationExpression = createApplication({
                  variables,
                  rawArgument: child.argument,
                  replacedVariableIdToNewVariable:
                    child.replacedVariableIdToNewVariable,
                  valueType: expression.valueType,
                  replaceArgument,
                  newVariableName: child.newVariableName,
                  setExpressionEditorSelectedItem: (newSelectedItem) =>
                    context.setExpressionEditorState({
                      ...context.expressionEditorState,
                      selectedItem: newSelectedItem,
                    }),
                });
                setExpression(applicationExpression);
              }}
              parentExpression={expression}
              setParentExpression={setExpression}
              includeExpressionOption={includeExpressionOption}
            />
          ),
        },
        ...Object.entries(expression.cases)
          .sort((a, b) => casePosition[a[0]] - casePosition[b[0]])
          .map(([enumValue, caseExpression]) => ({
            type: LabeledExpressionControlListItemControlType,
            label: getTextLabel(toStartCase(enumValue.toLowerCase())),
            count: caseExpression
              ? context.evaluations[caseExpression.id]
              : undefined,
            isRed: !schemaEnumValues[enumValue],
            options:
              !readOnly && !schemaEnumValues[enumValue]
                ? [
                    <DeleteButton
                      size="x-small"
                      key={`delete${enumValue}`}
                      onClick={(): void => {
                        const { [enumValue]: _, ...cases } = expression.cases;
                        setExpression({ ...expression, cases });
                      }}
                    />,
                  ]
                : [],
            expressionControl: (
              <ExpressionControl
                context={context}
                variables={variables}
                setVariableName={setVariableName}
                valueTypeConstraint={childValueTypeConstraint}
                expression={caseExpression}
                setExpression={(newExpression: Expression | null): void =>
                  setExpression({
                    ...expression,
                    cases: {
                      ...expression.cases,
                      [enumValue]: newExpression,
                    },
                  })
                }
                lift={getEnumSwitchCaseExpressionFunction({
                  variables,
                  enumValue,
                  expression,
                  setExpression,
                  setExpressionEditorSelectedItem: (newSelectedItem) =>
                    context.setExpressionEditorState({
                      ...context.expressionEditorState,
                      selectedItem: newSelectedItem,
                    }),
                })}
                parentExpression={expression}
                setParentExpression={setExpression}
                includeExpressionOption={includeExpressionOptionForCases}
              />
            ),
          })),
      ]}
    />
  );
}

export function getEnumSwitchExpressionSchemaValuesAndCasePositions(
  schema: Schema,
  expression: EnumSwitchExpression
): {
  casePosition: { [enumValue: string]: number };
  schemaEnumValues: { [enumValue: string]: EnumValueSchema };
} {
  const schemaEnumValues =
    expression.control && expression.control.valueType.type === "EnumValueType"
      ? schema.enums[expression.control.valueType.enumTypeName]?.values || {}
      : {};

  const casePosition: { [enumValue: string]: number } = {};

  Object.keys(expression.cases).forEach((enumValue) => {
    if (!schemaEnumValues[enumValue]) {
      // Show extra enum value names at the top
      casePosition[enumValue] = -1;
    }
  });

  const missingEnumValues: string[] = [];
  Object.keys(schemaEnumValues).forEach((schemaEnumValue, index) => {
    // Show valid enum value names in the order they appear in the schema
    casePosition[schemaEnumValue] = index;
    if (typeof expression.cases[schemaEnumValue] === "undefined") {
      missingEnumValues.push(schemaEnumValue);
    }
  });
  return {
    casePosition,
    schemaEnumValues,
  };
}

export function getEnumSwitchCaseExpressionFunction({
  variables,
  enumValue,
  expression,
  setExpression,
  setExpressionEditorSelectedItem,
}: {
  variables: VariableMap;
  enumValue: string;
  expression: EnumSwitchExpression;
  setExpression: (newExpression: Expression | null) => void;
  setExpressionEditorSelectedItem: (
    newSelectedItem: SelectedItem | null
  ) => void;
}): LiftFunction {
  return (child): void => {
    function replaceArgument(
      variable: VariableExpression | ApplicationExpression
    ): EnumSwitchExpression {
      const newExpression: EnumSwitchExpression = {
        ...expression,
        cases: {
          ...expression.cases,
          [enumValue]: child.replaceArgument(variable),
        },
      };
      return newExpression;
    }
    const applicationExpression = createApplication({
      variables,
      rawArgument: child.argument,
      replacedVariableIdToNewVariable: child.replacedVariableIdToNewVariable,
      valueType: expression.valueType,
      replaceArgument,
      newVariableName: child.newVariableName,
      setExpressionEditorSelectedItem,
    });
    setExpression(applicationExpression);
  };
}

export function getEnumSwitchCaseIncludeExpressionOptionFunction(
  expression: EnumSwitchExpression,
  parentIncludeExpressionOption: IncludeExpressionOptionFunction
): IncludeExpressionOptionFunction {
  return ({ expressionOption, expressionOptionParent }) => {
    if (
      expression.control?.type === "GetFieldExpression" &&
      expression.control.object?.type === "VariableExpression" &&
      expressionOption.type === "GetFieldExpression" &&
      expressionOption.object?.type === "VariableExpression" &&
      expressionOption.object.variableId ===
        expression.control.object.variableId &&
      expressionOption.fieldPath === expression.control.fieldPath
    ) {
      return false;
    }
    return parentIncludeExpressionOption({
      expressionOption,
      expressionOptionParent,
    });
  };
}
