import toStartCase from "@hypertune/shared-internal/src/toStartCase";
import { ArrowsClockwise } from "@phosphor-icons/react";
import ConfettiExplosion from "react-confetti-explosion";
import { motion } from "framer-motion";
import Card from "../../../components/Card";
import Label from "../../../components/Label";
import Toggle from "../../../components/Toggle";
import Flag from "../../../components/icons/Flag";
import { lighterGreyHex } from "../../../lib/constants";
import { FrameworkDefinition, FrameworkImage } from "./Step2GenerateClient";
import SuccessCircle from "../../../components/icons/SuccessCircle";
import { Dot, Line, LinkIcon } from "../../../components/ProgressShapes";

export default function ProgressView({
  step,
  flagName,
  flagValue,
  codeGenerated,
  appConnected,
  selectedFramework,
  setupCompleted,
  setSetupCompleted,
}: {
  step: number;
  flagName: string;
  flagValue: boolean;
  codeGenerated: boolean;
  appConnected: boolean;
  selectedFramework: FrameworkDefinition;
  setupCompleted: boolean;
  setSetupCompleted: (completed: boolean) => void;
}): React.ReactElement {
  return (
    <div className="flex h-full flex-col items-center py-14">
      <FlagCard flagName={flagName} flagValue={flagValue} />
      {(step >= 2 || codeGenerated || appConnected) && (
        <>
          <Dot className="-mt-[6px]" selected={appConnected} visible />
          <Line className="" selected={appConnected} />
          <Dot
            className={appConnected ? "-mb-[6px]" : "mb-10"}
            selected={false}
            visible={!appConnected}
          />
          {appConnected && !setupCompleted && (
            <ConfettiExplosion
              zIndex={300}
              onComplete={() => setSetupCompleted(true)}
            />
          )}
          <LinkIcon className="" visible={appConnected} />
          <Dot
            className={appConnected ? "-mt-[6px]" : "mt-10"}
            selected={false}
            visible={!appConnected}
          />
          <Line className="" selected={appConnected} />
          <Dot className="-mb-[6px]" selected={appConnected} visible />
          <FrameworkCard
            selectedFramework={selectedFramework}
            step={step}
            codeGenerated={codeGenerated}
            appConnected={appConnected}
          />
        </>
      )}
    </div>
  );
}

function FlagCard({
  flagName,
  flagValue,
}: {
  flagName: string;
  flagValue: boolean;
}): React.ReactElement | null {
  return (
    <Card layout="horizontal" className="w-[345px] select-none overflow-hidden">
      <div
        className={`flex flex-row items-center gap-3 bg-bg-default font-semibold ${
          !flagName ? "text-tx-disabled" : "text-tx-default"
        }`}
      >
        <Flag color={!flagName ? lighterGreyHex : undefined} />
        <p className="max-w-[239px] overflow-x-clip text-ellipsis whitespace-nowrap">
          {toStartCase(flagName) || "Flag name"}
        </p>
      </div>
      <Toggle
        size="large"
        disabled
        value={flagValue}
        className="cursor-default"
      />
    </Card>
  );
}

function FrameworkCard({
  step,
  codeGenerated,
  appConnected,
  selectedFramework,
}: {
  step: number;
  codeGenerated: boolean;
  appConnected: boolean;
  selectedFramework: FrameworkDefinition;
}): React.ReactElement | null {
  return (
    <Card
      layout="horizontal-with-icon"
      className="w-[345px] grid-rows-[47px] gap-3"
    >
      <FrameworkImage
        framework={selectedFramework.id}
        imageStyle={{ width: 30 }}
      />
      <div className="flex flex-col">
        <div className="flex flex-col gap-2">
          {(step >= 2 || codeGenerated) && (
            <FrameworkCardStep
              text="Waiting for client generation..."
              completedText={
                selectedFramework.requireCodegen
                  ? "Client successfully generated"
                  : "No client generation needed"
              }
              completed={codeGenerated || !selectedFramework.requireCodegen}
            />
          )}
          {(step === 3 || appConnected) && (
            <FrameworkCardStep
              text="Waiting for flag evaluation..."
              completedText="Flag successfully evaluated"
              completed={appConnected}
            />
          )}
        </div>
      </div>
    </Card>
  );
}

function FrameworkCardStep({
  text,
  completedText,
  completed,
}: {
  text: string;
  completedText: string;
  completed: boolean;
}): React.ReactElement | null {
  return (
    <div className="flex flex-row items-center gap-2">
      {completed ? <SuccessCircle size={18} /> : <Spinner />}
      {completed ? (
        <Label type="title4" className="text-tx-default">
          {completedText}
        </Label>
      ) : (
        <Label type="title4" className="text-tx-muted">
          {text}
        </Label>
      )}
    </div>
  );
}

function Spinner(): React.ReactElement {
  return (
    <motion.div
      className="rounded-full bg-base-grey-3-light p-[2px]"
      initial={{ rotate: 0 }}
      animate={{ rotate: 360 }}
      transition={{ duration: 2, repeat: Infinity }}
    >
      <ArrowsClockwise color={lighterGreyHex} size={14} weight="regular" />
    </motion.div>
  );
}
